import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Terms = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "terms.title" })} />
      <section className="w-full md:w-3/4 flex flex-col items-center px-3">
        <article className="flex flex-col border border-gray-400 mb-3 bg-white rounded">
          <div className="flex flex-col justify-start p-6">
            {/* Title */}
            <h1 className="text-3xl font-bold hover:text-gray-700 pb-6">
              {intl.formatMessage({ id: "terms.title" })}
            </h1>
            {/* 記事本文 */}
            <p className="pt-6 pb-3">
              {intl.formatMessage({ id: "terms.p1" })}
            </p>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="book">
                📚
              </span>
              {intl.formatMessage({ id: "terms.h1" })}
            </h1>
            <p className="pb-3">
              {intl.formatMessage({ id: "terms.p2" })}
              <span className="font-bold">
                {intl.formatMessage({ id: "terms.p3" })}
              </span>
              {intl.formatMessage({ id: "terms.p8" })}
            </p>
            <p className="pb-3">
              {intl.formatMessage({ id: "terms.p4" })}
              <span className="font-bold">
                {intl.formatMessage({ id: "terms.p4_1" })}
              </span>
            </p>
            <div className="pb-3">
              <ul className="list-disc list-inside ml-3">
                <li>{intl.formatMessage({ id: "terms.li1" })}</li>
                <li>{intl.formatMessage({ id: "terms.li2" })}</li>
                <li>{intl.formatMessage({ id: "terms.li3" })}</li>
                <li>{intl.formatMessage({ id: "terms.li4" })}</li>
              </ul>
            </div>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="smart phone">
                📱
              </span>
              {intl.formatMessage({ id: "terms.h2" })}
            </h1>
            <p className="pb-3">{intl.formatMessage({ id: "terms.p5" })}</p>
            <p className="pb-3">{intl.formatMessage({ id: "terms.p6" })}</p>
            <h1 className="text-xl font-bold py-6">
              <span role="img" aria-label="chart">
                📈
              </span>
              {intl.formatMessage({ id: "terms.h3" })}
            </h1>
            <p className="pb-3">{intl.formatMessage({ id: "terms.p7" })}</p>
          </div>
        </article>
      </section>
    </Layout>
  )
}
export default Terms
